const togglePasswordButton = document.querySelector(".btn-toggle-password");
const passwordInput = document.querySelector(".password-toggle-input");

if (togglePasswordButton && passwordInput) {
  togglePasswordButton.addEventListener("click", () => {
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
      togglePasswordButton.innerHTML = '<i class="fas fa-eye-slash"></i>';
    } else {
      passwordInput.type = "password";
      togglePasswordButton.innerHTML = '<i class="fas fa-eye"></i>';
    }
  });
}